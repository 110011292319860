








import { Vue, Component } from 'vue-property-decorator';
import Page from '@/layouts/Page.vue';
import BackButtonLayout from '@/layouts/management/BackButtonLayout.vue';
import { UserNotificationStores } from '@/store/user_notifications';
import SendUserNotificationForm from '@/components/user_notifications/SendUserNotificationForm.vue';

@Component({
  components: {
    Page,
    BackButtonLayout,
    SendUserNotificationForm,
  },
})
export default class SendUserNotifications extends Vue {
  userNotificationStore = UserNotificationStores;

  unsubscribe: () => void = () => {};

  handleSubmit(payload: any) {
    this.userNotificationStore.sendUserNotification(payload);
  }

  created() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === 'user_notification/SEND_USER_NOTIFICATION_SUCCESS') {
        this.$router.replace('/notification_settings');
      }
    });
  }

  beforeDestroy() {
    this.unsubscribe();
  }
}
