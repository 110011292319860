var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{class:["columns"]},[_c('div',{class:["column"]},[_c('div',{class:[_vm.$style.inputContainer]},[_c('ValidationProvider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('h5',{staticClass:"label"},[_vm._v("Title:")]),_c('b-input',{attrs:{"placeholder":"Title"},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}}),_c('span',{class:_vm.$style.errorMessage},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{class:[_vm.$style.inputContainer]},[_c('ValidationProvider',{attrs:{"name":"Content","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('h5',{staticClass:"label"},[_vm._v("Content:")]),_c('b-input',{attrs:{"placeholder":"Content","maxlength":"200","type":"textarea"},model:{value:(_vm.formData.content),callback:function ($$v) {_vm.$set(_vm.formData, "content", $$v)},expression:"formData.content"}}),_c('span',{class:_vm.$style.errorMessage},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{class:[_vm.$style.inputContainer]},[_c('ValidationProvider',{ref:"type",attrs:{"name":"Type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Type"}},[_c('Dropdown',{attrs:{"data":_vm.notificationTypelist,"placeholder":'Select a notification type',"selectedText":_vm.selectedNotificationType,"previousSelectedValue":_vm.previousSelectedNotificationType},on:{"select":_vm.onNotificationTypeSelect,"updateState":_vm.updateNotificationTypeState}})],1),_c('span',{class:_vm.$style.errorMessage},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])]),_c('div',{class:["columns"]},[_c('div',{class:["column"]},[_c('div',{class:[_vm.$style.inputContainer]},[_c('ValidationProvider',{ref:"user",attrs:{"name":"Student","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Student"}},[_c('Multiselect',{attrs:{"options":_vm.userData,"searchable":true,"close-on-select":true,"clear-on-select":false,"placeholder":"Type Student Email to search","label":"text","track-by":"value"},on:{"select":_vm.onUserSelect,"search-change":_vm.getUserDropdown},model:{value:(_vm.selectedUser),callback:function ($$v) {_vm.selectedUser=$$v},expression:"selectedUser"}},[_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("No student found. Consider changing the search keyword.")])])],1),_c('span',{class:_vm.$style.errorMessage},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])]),_c('div',{class:["columns"]},[_c('div',{class:["column"]},[_c('div',{class:[_vm.$style.inputContainer]},[_c('h5',{staticClass:"label"},[_vm._v("Redirect Action:")]),_c('b-switch',{model:{value:(_vm.hasRedirectAction),callback:function ($$v) {_vm.hasRedirectAction=$$v},expression:"hasRedirectAction"}},[_vm._v(" "+_vm._s(_vm.hasRedirectAction ? "Enabled" : "Disabled")+" ")])],1),(_vm.hasRedirectAction)?_c('div',{class:[_vm.$style.inputContainer]},[_c('ValidationProvider',{attrs:{"name":"Redirect Url","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('h5',{staticClass:"label"},[_vm._v("Redirect Url:")]),_c('b-input',{attrs:{"placeholder":"Redirect Url"},model:{value:(_vm.formData.actionurl),callback:function ($$v) {_vm.$set(_vm.formData, "actionurl", $$v)},expression:"formData.actionurl"}}),_c('span',{class:_vm.$style.errorMessage},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()])]),_c('div',{class:_vm.$style.buttonContainer},[_c('input',{attrs:{"type":"submit","disabled":invalid},domProps:{"value":'Add'}})])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }