

































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import Dropdown from '@/components/common/Dropdown.vue';
import Upload from '@/components/common/Upload.vue';
import { SendUserNotificationFormPayload } from '@/models/user_notifications';
import { ValidationObserver } from 'vee-validate';
import { DropdownItem } from '@/models/dropdown';
import { UserNotificationStores } from '@/store/user_notifications';
import { UserStores } from '@/store/users';
import Multiselect from 'vue-multiselect'

const actionType = 'EXTERNAL_LINK';

@Component({
  components: {
    Dropdown,
    Upload,
    ValidationObserver,
    Multiselect,
  },
})
export default class SendUserNotificationForm extends Vue {
  userNotificationStore = UserNotificationStores;

  userListStore = UserStores.list;
  notificationTypeData: DropdownItem[] = [];
  userData: DropdownItem[] = [];
  selectedNotificationType = '- Select -';
  previousSelectedNotificationType = '';

  hasRedirectAction = false;
  selectedUser = null;

  formData: SendUserNotificationFormPayload = {
    title: '',
    content: '',
    type: '',
    actiontype: '',
    actionurl: '',
    userid: 0,
  };

  get notificationTypelist() {
    return this.notificationTypeData;
  }

  fetchNotifcationTypes() {
    this.userNotificationStore.getUserNotificationTypes({
      page: 1,
      limit: 100,
    });
  }

  onNotificationTypeSelect(item: DropdownItem) {
    this.updateNotificationTypeState(item);
  }

  onUserSelect(item: DropdownItem) {
    this.updateUserState(item);
  }

  updateNotificationTypeState(item: DropdownItem) {
    this.selectedNotificationType = item.text;
    this.formData.type = item.value;
  }

    updateUserState(item: DropdownItem) {
    this.formData.userid = parseInt(item.value);
  }
  
  mounted() {
    this.fetchNotifcationTypes();
  }

  onSubmit() {
    const { ...formData } = this.formData;
    if(this.hasRedirectAction && formData.actionurl.length > 0) {
      formData.actiontype = actionType;
    } else {
      formData.actiontype = '';
      formData.actionurl = '';
    }

    this.$emit('submit', {
      ...formData,
    });
  }

  getUserDropdown(keyword: string) {
    if(keyword.length > 3) {
      this.userListStore.retrieveUsers({
        page: 1,
        limit: 20,
        keyword: keyword,
        filter: 'student',
      });
    }
  }

  @Watch(`userNotificationStore.userNotificationTypes`)
  updateNotificationTypeDropdown() {
    if(this.userNotificationStore.userNotificationTypes.data) {
      this.notificationTypeData = [];
      this.userNotificationStore?.userNotificationTypes.data.forEach((t) => {
        this.notificationTypeData.push({
          text: t.type,
          value: t.type,
          uniqueKey: `${t.id}_`,
        });
      });
    }
  }

  @Watch(`userListStore.response`)
  setUsers() {
    if (this.userListStore.response) {
      this.userData = [];
      this.userListStore.response.objects.forEach((item) => {
        this.userData.push({
          text: item.name  + " / " + item.email,
          value: item.id.toString(),
          uniqueKey: `${item.id}_`,
        });
      });
    }
  }
}
